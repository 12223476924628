<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import CreateVideo from '@components/video/createvideo'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Create',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    CreateVideo,
    // CreateSub,
    // PageHeader
  },
  data() {
    return {
      data: {},
      is_fighter: null,
    }
  },
  mounted() {
    this.onLode()
  },
  methods: {
    async onLode() {
      await axios
        .get(`/api/category/sub/${this.$route.params.id}`)
        .then((res) => {
          this.data = res.data
          // console.log(this.data)
        })
        .catch((err) => {
          // alert(err.response.data.message)
        })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0"></h4>
      </div>
      <div v-if="data.is_fighter == undefined" class="col-xl-6">
        <button
          class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          @click="is_fighter = true"
        >
          <feather type="file-plus"></feather>Add Fighter
        </button>

        <button
          class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          @click="is_fighter = false"
        >
          <feather type="image"></feather>&nbsp;Create Video
        </button>
      </div>
      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <CreateVideo
              :sub_subcategory_id="this.$route.params.id"
            ></CreateVideo>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
